import { HttpClient,HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient) { }


  register(data:any):Observable <any>{
    return this.http.post('http://localhost:5500/auth/register',data);
  }

  signin(data:any):Observable <any>{

    return this.http.post('http://localhost:5500/auth/login',data);
  }


  dashBoard(token){
    const headers = new HttpHeaders({
      'Content-Type' : 'application/json',
      'Authorization' : `Bearer ${token}`
    })
    return this.http.get('http://localhost:5500/auth/dashboard', {headers : headers})
  }

}
