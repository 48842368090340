// import { Component, OnInit } from '@angular/core';
// import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// import { CommonService } from '../service/common.service';
// import { Router } from '@angular/router';

// @Component({
//   selector: 'app-loginform',
//   templateUrl: './loginform.component.html',
//   styleUrls: ['./loginform.component.css']
// })
// export class LoginformComponent implements OnInit {

//   loginForm: FormGroup;
//   loginSuccess: boolean = false;
//   errorMessage: string = '';
//   signupForm: FormGroup;
//   errorMessageRegistration: string = '';
//   successMessageRegistration: string = '';

//   constructor(private fb: FormBuilder, private authService: CommonService, public router: Router) { }

//   ngOnInit(): void {
//     this.loginForm = this.fb.group({
//       email: ['', [Validators.required, Validators.email]],
//       password: ['', [Validators.required]]
//     });
//     this.signupForm = this.fb.group({
//       firstName: ['', Validators.required],
//       lastName: ['', Validators.required],
//       email: ['', [Validators.required, Validators.email]],
//       phoneNo: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
//       collegeName: ['', Validators.required],
//       password: ['', Validators.required],
//
//   }

///////////// STUDENT REGISTRATION /////////////////

//

//   onSubmit(): void {
//     if (this.signupForm.valid) {
//       const formData = this.signupForm.value;
//       delete formData.confirmPassword;
//       this.authService.signup(formData).subscribe(
//         res => {
//           this.successMessageRegistration = res.message;
//           this.signupForm.reset();
//           this.router.navigate(['/loginform'])
//           console.log(res)
//         },
//         error => {
//           this.errorMessageRegistration = 'something went wrong';
//         }
//       );
//     }
//   }

//   ///////////// STUDENT LOGIN  ///////////////////////

//   login(): void {
//     if (this.loginForm.valid) {
//       const { email, password } = this.loginForm.value;
//       this.authService.login(email, password).subscribe(
//         res => {
//           this.loginSuccess = true;
//           this.errorMessage = '';
//           const token = res.token;
//           localStorage.setItem('jwtToken', token);
//           localStorage.setItem('email', email);
//           setTimeout(() => {
//             this.router.navigate(['/studentDashbord']);
//           }, 2000);
//         },
//         error => {
//           this.loginSuccess = false;
//           this.errorMessage = "Invalid email or password";
//         }
//       );
//     }
//   }

// }

import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { FormControl } from "@angular/forms";
import { CommonService } from "../service/common.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-loginform",
  templateUrl: "./loginform.component.html",
  styleUrls: ["./loginform.component.css"],
})
export class LoginformComponent implements OnInit {
  loginForm: FormGroup;
  loginSuccess: boolean = false;
  errorMessage: string = "";
  signupForm: FormGroup;
  errorMessageRegistration: string = "";
  successMessageRegistration: string = "";

  constructor(
    private fb: FormBuilder,
    private authService: CommonService,
    public router: Router
  ) {}

  ngOnInit(): void {
    this.loginForm = this.fb.group({
      email: ["", [Validators.required, Validators.email]],
      password: ["", [Validators.required]],
    });
    this.signupForm = this.fb.group({
      firstName: ["", Validators.required],
      lastName: ["", Validators.required],
      email: ["", [Validators.required, Validators.email]],
      phoneNo: ["", [Validators.required, Validators.pattern("^[0-9]*$")]],
      collegeName: ["", Validators.required],
      password: ["", Validators.required],
    });
  

    const signUpButton: HTMLElement | null = document.getElementById("signUp");
    const signInButton: HTMLElement | null = document.getElementById("signIn");
    const container: HTMLElement | null = document.getElementById("container");

    if (signUpButton && signInButton && container) {
      signUpButton.addEventListener("click", () => {
        container.classList.add("right-panel-active");
      });

      signInButton.addEventListener("click", () => {
        container.classList.remove("right-panel-active");
      });
    }
  }

  noWhitespace(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
  
    return isValid ? null : { whitespace: true };
  }
  
  onSubmit(): void {
    if (this.signupForm.valid) {
      const formData = this.signupForm.value;
      this.authService.signup(formData).subscribe(
        (res) => {
          this.successMessageRegistration = res.message;
          this.signupForm.reset();
          this.router.navigate(["/loginform"]);
          console.log(res);
        },
        (error) => {
          this.errorMessageRegistration = error.message;
        }
      );
    }
  }
  login(): void {
    if (this.loginForm.valid) {
      const { email, password } = this.loginForm.value;
      this.authService.login(email, password).subscribe(
        (res) => {
          this.loginSuccess = true;
          this.errorMessage = "";
          const token = res.token;
          localStorage.setItem("jwtToken", token);
          localStorage.setItem("email", email);
          setTimeout(() => {
            this.router.navigate(["/student-dashboard"]);
          }, 2000);
        },
        (error) => {
          this.loginSuccess = false;
          this.errorMessage = "Invalid email or password";
        }
      );
    }
  }
}
