import { Component, OnInit } from '@angular/core';
import { FormGroup,FormBuilder,Validators} from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/service/auth.service';


@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
})
export class RegisterComponent implements OnInit {
  singupForm! : FormGroup;
  message:string ='';
  isProcess:boolean = false;

  constructor(private fb:FormBuilder, private auth:AuthService, private router : Router) {
    this.singupForm = this.fb.group({
      'username':['',Validators.required],
      'email':['',Validators.required],
      'password':['',Validators.required]
    })
  }

  ngOnInit(): void {}

  register(){
    this.isProcess=true;
    const data = this.singupForm.value;


    this.auth.register(data).subscribe(res=>{

    if(res && res['status'] === 'ok' && res['data']['_id'])
    {
      alert("User Registration Successfull")
      this.router.navigate(['/auth/login'])
    }
    else if (res && res['status'] === 'error'){
      alert("Email Already Exists");
    }

    }, (err)=>{
      if(err){
        console.log("error in register")
      }
    })
    }
}
