import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators, FormControl } from "@angular/forms";
import { Router } from "@angular/router";
import { CommonService } from "src/app/service/common.service";

@Component({
  selector: 'app-admin-panel',
  templateUrl: './admin-panel.component.html',
  styleUrls: ['./admin-panel.component.css']
})
export class AdminPanelComponent implements OnInit {

  adminForm: FormGroup;
  adminSuccess: boolean = false;
  error: string = "";
  navbarOpen: any;

  constructor(
    private fb: FormBuilder,
    private authService: CommonService,
    public router: Router
  ) { }

  ngOnInit(): void {
    this.adminForm = this.fb.group({
      email: ["", [Validators.required, Validators.email]],
      password: ["", [Validators.required]],
    });
  }

  noWhitespace(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;

    return isValid ? null : { whitespace: true };
  }

  adminLogin(): void {
    if (this.adminForm.valid) {
      const { email, password } = this.adminForm.value;
      // console.log("Form data:", this.adminForm.value);
      this.authService.admin_login(email, password).subscribe(
        (res) => {
          this.adminSuccess = true;
          this.error = "";
          const adminToken = res.token;
          localStorage.setItem("adminToken", adminToken);
          localStorage.setItem("email", email);
          setTimeout(() => {
            this.router.navigate(["admin-dashboard/admin-dashboardMain"]);
          }, 2000);
        },
        (error) => {
          this.adminSuccess = false;
          this.error = "Invalid email or password";
        }
      );
    } else {
      console.log("Form is invalid");
    }
  }


  setNavbarOpen() { }

}
