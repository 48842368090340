import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators,FormControl} from "@angular/forms";
import { Router } from "@angular/router";
import { CommonService } from "src/app/service/common.service";

@Component({
  selector: 'app-trainings',
  templateUrl: './trainings.component.html',
  styleUrls: ['./trainings.component.css']
})
export class TrainingsComponent implements OnInit {

  trainingForm: FormGroup;
  loading:boolean = false;
  text:string;
  success:boolean;
  failure:boolean;
  trainingTypes:Array<any>;
  selectedCourseFee:string;
  isCourseSelected:boolean = false;
  

  constructor( private common:CommonService,private formBuilder:FormBuilder) { 

  }

  ngOnInit(): void {
    this.trainingForm = this.formBuilder.group({
      fullName:  ['', [Validators.required , this.noWhitespace,  Validators.pattern("[a-zA-Z][a-zA-Z ]+")]],
      email: ['', [Validators.required, Validators.email ,Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
      phoneNo: ['', Validators.required , Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")],
      courseType:  ['',[ Validators.required , this.noWhitespace, Validators.pattern("[a-zA-Z][a-zA-Z ]+")]],
      collegeName:  ['',[ Validators.required, this.noWhitespace]],
      courseSelected:  ['', Validators.required],
      courseFee:  ['', Validators.required],
  });
    

  this.common.getTrainingTypes().subscribe((res)=>{
    this.trainingTypes = res.data
  })
    
  
}



getCourseAmount(){
  this.isCourseSelected = true
  const seletedCourse = this.trainingForm.controls['courseSelected'].value
  this.selectedCourseFee = this.trainingTypes[seletedCourse].amount
}

noWhitespace(control: FormControl) {
  const isWhitespace = (control.value || '').trim().length === 0;
  const isValid = !isWhitespace;

  return isValid ? null : { whitespace: true };
}


onSubmit(){
  const data = this.trainingForm.value;
  data.courseFee = this.selectedCourseFee
  if (this.trainingForm.invalid) {
    return;
  }
  this.loading = true;
  this.common.trainings(data).subscribe(
    (res) =>{
      if (res && res["success"] === "Data stored and Email sent successfully") {
      this.success = true;
      this.trainingForm.reset();
      this.loading = false;
      setTimeout(()=>{
        this.success = false;
      },10000)

    }
    else if(res && res["error"]) {
      this.failure = true;
      this.trainingForm.reset();
      this.loading = false;
      setTimeout(()=>{
        this.failure = false;
      },10000)
    }

  },
  (err) => {
    if (err) {
      this.loading = false;
    }
  }
);
}
}

