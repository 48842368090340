import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { Router } from "@angular/router";
import { CommonService } from "src/app/service/common.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { interval, Subscription } from "rxjs";

@Component({
  selector: "app-liveclasses",
  templateUrl: "./liveclasses.component.html",
  styleUrls: ["./liveclasses.component.css"],
})
export class LiveclassesComponent implements OnInit, OnDestroy {
  myForm: FormGroup;
  formVisible: boolean = false;
  formHeadData: string = "";
  allData: any[] = [];

  // Carousel properties
  currentIndex = 0;
  totalSlides = 6; // Update this to the number of slides you have
  autoSlideInterval: number = 3000; // Interval for automatic sliding in milliseconds
  private intervalSubscription: Subscription;

  @ViewChild("liveClasses", { static: false }) liveClasses: ElementRef;

  constructor(
    private router: Router,
    public common: CommonService,
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this.showSection("codekata");
    this.getAllData();
    this.startAutoSlide(); // Initialize the carousel auto-slide
  }

  ngOnDestroy() {
    if (this.intervalSubscription) {
      this.intervalSubscription.unsubscribe(); // Clean up subscription on destroy
    }
  }

  formData() {
    this.myForm = this.fb.group({
      name: ["", Validators.required],
      email: ["", [Validators.required, Validators.email]],
      phone: ["", [Validators.required, Validators.pattern("^[0-9]{10}$")]],
      education: ["", Validators.required],
      profile: ["", Validators.required],
    });
  }

  onSubmit() {
    if (this.myForm.valid) {
      let formData = this.myForm.value;
      this.common.postLiveClassesFormData(formData).subscribe((res) => {
        if (res && res === "Form submitted successfully") {
          console.log(res);
        }
      });
    }
  }

  trimPhoneNumber() {
    const phoneControl = this.myForm.get("phone");
    if (phoneControl) {
      phoneControl.setValue(phoneControl.value.trim());
    }
  }

  navigateToLiveClasses(course: any) {
    this.router.navigate(["/liveclassesDynamic", course]);
    // const encodedCourseName = encodeURIComponent(course);
    // this.router.navigate([`/liveclassesDynamic/${encodedCourseName}`]);

  }

  showSection(sectionId: string): void {
    document.querySelectorAll(".content-section").forEach((section) => {
      section.classList.add("hidden");
    });

    const targetSection = document.getElementById(sectionId);
    if (targetSection) {
      targetSection.classList.remove("hidden");
      targetSection.classList.add("block");
    }

    document.querySelectorAll(".nav-link").forEach((link) => {
      link.classList.remove(
        "text-green-500",
        "font-semibold",
        "border-b-2",
        "border-green-500"
      );
      link.classList.add("text-gray-600");
    });

    const activeLink = document.querySelector(
      `.nav-link[data-target="${sectionId}"]`
    );
    if (activeLink) {
      activeLink.classList.add(
        "text-green-500",
        "font-semibold",
        "border-b-2",
        "border-green-500"
      );
      activeLink.classList.remove("text-gray-600");
    }
  }

  scrollToClasses() {
    this.liveClasses.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  }

  getAllData(): void {
    this.common.getAllData().subscribe(
      (data) => {
        this.allData = data;
        console.log("data added");
      },
      (error) => {
        console.error("Error fetching data:", error);
      }
    );
  }

  formView(data: string) {
    this.formVisible = true;
    this.formHeadData = data;
  }

  formHide() {
    this.formVisible = false;
  }

  // Carousel methods
  startAutoSlide() {
    this.intervalSubscription = interval(this.autoSlideInterval).subscribe(
      () => {
        this.nextSlide();
      }
    );
  }

  prevSlide() {
    this.currentIndex =
      this.currentIndex > 0 ? this.currentIndex - 1 : this.totalSlides - 1;
  }

  nextSlide() {
    this.currentIndex =
      this.currentIndex < this.totalSlides - 1 ? this.currentIndex + 1 : 0;
  }
}
