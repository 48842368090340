import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';



@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router) { }

  canActivate(): boolean {
    const token = localStorage.getItem('jwtToken');
    if (token) {
      // Token exists, user is authenticated
      return true;
    } else {
      // Token does not exist, user is not authenticated
      this.router.navigate(['/loginform']);
      return false;
    }
  }

}
