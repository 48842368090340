import { Component, OnInit } from '@angular/core';
import {FormGroup,FormBuilder,Validators} from '@angular/forms'
import { Router } from '@angular/router';
import { AuthService } from 'src/app/service/auth.service';
@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
})
export class LoginComponent implements OnInit {

  loginForm:FormGroup;
  constructor(private fb:FormBuilder, private auth:AuthService ,private router:Router) {
    this.loginForm = this.fb.group({
      'email':['',Validators.required],
      'password':['',Validators.required]
    })
  }

  ngOnInit(): void {}

  login(){
    const data = this.loginForm.value;
    this.auth.signin(data).subscribe((res)=>{
    if(res && res['status'] === 'ok' && res['data']['response'] && res['data']['authToken']){
      localStorage.setItem('token',res['data']['authToken'])

       this.router.navigate(['/profile']);
       alert("Login Successfull")

    }else if(res && res['data']['response'] === false){
      alert("Entered Details Are Incorrect")

    }

    },(err)=>{
      if(err){

        console.log("error in login")
      }
    })
  }
}
