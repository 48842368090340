import { Component, OnInit, ElementRef, ViewChild, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/service/common.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-admin-projects',
  templateUrl: './admin-projects.component.html',
  styleUrls: ['./admin-projects.component.css']
})
export class AdminProjectsComponent implements OnInit {
  navbarOpen = false;
  profileDropdownOpen = false;
  sidebarOpen = false;
  formVisible = false;
  selectedVideo: File | null = null;
  selectedDocument: File | null = null;
  selectedFile: File | null = null;



  @ViewChild('profileDropdown') profileDropdown: ElementRef;

  constructor(private router: Router, private route: ActivatedRoute, private eRef: ElementRef, private server: CommonService) { }

  ngOnInit(): void {
    this.route.url.subscribe(() => {
      // Show the form when the route matches admin-projects
      if (this.router.url === '/admin-dashboard/admin-projects') {
        this.formVisible = true;
      } else {
        this.formVisible = false;
      }
    });
  }

  toggleSidebar() {
    this.sidebarOpen = !this.sidebarOpen;
  }

  toggleProfileDropdown() {
    this.profileDropdownOpen = !this.profileDropdownOpen;
  }

  closeProfileDropdown() {
    this.profileDropdownOpen = false;
  }

  openSidebar() {
    this.sidebarOpen = true;
  }

  closeSidebar() {
    this.sidebarOpen = false;
  }

  showForm() {
    this.formVisible = true;
    this.openSidebar();
    this.closeProfileDropdown();
  }

  hideForm() {
    this.formVisible = false;
  }

  logout() {
    localStorage.removeItem('adminToken');
    this.router.navigate(['/admin-dashboard']);
  }

  openCertificates() {
    this.formVisible = false;  // Ensure form is hidden when navigating to another section
    this.router.navigate(['/admin-dashboard/admin-certificates']);  // Example navigation
  }

  openAttendance() {
    this.formVisible = false;  // Ensure form is hidden when navigating to another section
    this.router.navigate(['/admin-dashboard/admin-attendance']);  // Example navigation
  }

  openProjects() {
    this.formVisible = true;
    this.router.navigate(['/admin-dashboard/admin-projects']);
  }

  // Handle video file selection
  onVideoFileSelected(event: any): void {
    this.selectedVideo = event.target.files[0];
  }

  // Handle document file selection
  onDocumentFileSelected(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      this.selectedDocument = input.files[0];
    } else {
      this.selectedDocument = null;
    }
  }

  // Upload files
  uploadFiles(): void {
    if (this.selectedVideo) {
      console.log('Uploading video:', this.selectedVideo.name);
    }
    if (this.selectedDocument) {
      console.log('Uploading document:', this.selectedDocument.name);
    }
    // Implement the actual upload logic here
    alert('Files uploaded successfully!');
  }

  @HostListener('document:click', ['$event'])
  onClick(event: Event) {
    if (this.profileDropdownOpen && !this.eRef.nativeElement.contains(event.target)) {
      this.closeProfileDropdown();
    }
  }


  ////   upload File ///


  UplodFileSucess: boolean = false;

  onFileSelected(event: any) {
    this.selectedFile = event.target.files[0];
  }

  onUpload() {
    if (this.selectedFile) {
      this.server.uploadFile(this.selectedFile).subscribe(response => {
        console.log(response);
        this.UplodFileSucess = true;

      });
    }
  }

  //////////////////////////////


  // selectedVideo: File = null;
  videoUrl: SafeUrl = '';





  onVideoSelected(event) {
    this.selectedVideo = <File>event.target.files[0];
  }

  onUploadVideo() {
    this.server.uploadVideo(this.selectedVideo).subscribe(
      res => {
        console.log(res.message);
      },
      error => {
        console.error(error);
      }
    );
  }




} 
