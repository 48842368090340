import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CareersComponent } from '../views/careers/careers.component';
import { ViewDetailsComponent } from './view-details/view-details.component';
import { JobApplyFormComponent } from './job-apply-form/job-apply-form.component';
// import { FullStackDevComponent } from './full-stack-dev/full-stack-dev.component';

const routes: Routes = [ 
{ path: "view-details/:id", component: ViewDetailsComponent },
{ path: "job-apply-form", component:JobApplyFormComponent},
{ path: "", component:CareersComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class CareerOptionsRoutingModule {}