import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators,FormControl} from "@angular/forms";
import { Router } from "@angular/router";
import { CommonService } from "src/app/service/common.service";

@Component({
  selector: 'app-what-we-do',
  templateUrl: './what-we-do.component.html',
  styleUrls: ['./what-we-do.component.css']
})

export class WhatWeDoComponent implements OnInit {
  contactForm: FormGroup;
  loading:boolean = false;
  text:string;
  success:boolean;
  failure:boolean;

  constructor( private common:CommonService,private formBuilder:FormBuilder) {

  }

  ngOnInit(): void {
    this.contactForm = this.formBuilder.group({
      name: ['',[ Validators.required , this.noWhitespace ,  Validators.pattern("[a-zA-Z][a-zA-Z ]+")]],
      email: ['', [Validators.required, Validators.email,Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
      phoneno:['',[ Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
      message: ['']
    })
  }

  
  noWhitespace(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
  
    return isValid ? null : { whitespace: true };
  }

  onSubmit(){
    console.log(this.contactForm)
    const data = this.contactForm.value;
    if (this.contactForm.invalid) {
      return;
    }
    this.loading = true;
    this.common.contact(data).subscribe((res) =>{
      if(res && res['status'] === 'ok'){
        this.success = true;
        this.contactForm.reset();
        this.loading = false;
        setTimeout(()=>{
          this.success = false;
        },10000)

      }
      else if(res && res['status'] === 'error'){
        this.failure = true;
        this.contactForm.reset();
        this.loading = false;
        setTimeout(()=>{
          this.failure = false;
        },10000)
      }
    }
    ,(err)=>{
      if(err){
        this.loading = false;
      }
    })
  }

}
