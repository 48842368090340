import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/service/common.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';


declare var Razorpay: any;



@Component({
  selector: 'app-careers',
  templateUrl: './careers.component.html',
  styleUrls: ['./careers.component.css']
})
export class CareersComponent implements OnInit {

  adminUsers: any[] = [];
  selectedUser: any = null;
  selectedFile: File | null = null;
  technologies: any[] = [];
  currentSlideIndex = 0;
  itemsPerSlide = 4; // Adjust based on how many items you want to show per slide
  slideWidth = 150; // Width of each slide item in pixels
  containerWidth = 600; // Width of the slide container in pixels
  isPrevDisabled = true;
  isNextDisabled = false;
  paginationDots: number[] = [];


  constructor(private server: CommonService, private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.loadAdminUsers();

    // this.server.getTechnologies().subscribe(data => {
    //   this.technologies = data.technologieImages;

    // });

    this.updatePaginationDots();
  }

  loadAdminUsers(): void {
    this.server.getAllAdminUserDetails().subscribe(
      (data: any[]) => {
        this.adminUsers = data;
      },
      (error) => {
        console.error('Error fetching admin user details:', error);
      }
    );
  }

  selectUser(user: any): void {
    this.selectedUser = { ...user };
  }

  //////////////////

  updateUser(): void {
    if (this.selectedUser) {
      this.server.updateAdminUserDetails(this.selectedUser.email, this.selectedUser).subscribe(
        (response) => {
          console.log('User updated successfully:', response);
          this.loadAdminUsers();
        },
        (error) => {
          console.error('Error updating user:', error);
        }
      );
    }
  }


  /////////////////////
  getTransformStyle() {
    const offset = -this.currentSlideIndex * this.containerWidth;
    return {
      transform: `translateX(${offset}px)`
    };
  }

  prevSlide() {
    if (this.currentSlideIndex > 0) {
      this.currentSlideIndex--;
      this.updateNavigationButtons();
    }
  }

  nextSlide() {
    if (this.currentSlideIndex < this.paginationDots.length - 1) {
      this.currentSlideIndex++;
      this.updateNavigationButtons();
    }
  }

  goToSlide(index: number) {
    this.currentSlideIndex = index;
    this.updateNavigationButtons();
  }

  updateNavigationButtons() {
    this.isPrevDisabled = this.currentSlideIndex === 0;
    this.isNextDisabled = this.currentSlideIndex === this.paginationDots.length - 1;
  }

  updatePaginationDots() {
    const totalSlides = Math.ceil(this.technologies.length / this.itemsPerSlide);
    this.paginationDots = Array(totalSlides).fill(0).map((_, i) => i);
    this.updateNavigationButtons();
  }


  /////////////////////////////


  // selectedVideo: File = null;
  // videoUrl: SafeUrl = '';



  // ///////////////////////////
  // onVideoSelected(event) {
  //   this.selectedVideo = <File>event.target.files[0];
  // }


  // // videoUrl: string;
  // onWatch(videoId: number) {
  //   this.server.watchVideo(videoId).subscribe(
  //     (blob: Blob) => {
  //       const url = window.URL.createObjectURL(blob);
  //       this.videoUrl = this.sanitizer.bypassSecurityTrustUrl(url);
  //     },
  //     error => {
  //       console.error(error);
  //     }
  //   );
  // }




  // payMent(){
  //   // console.log("paymentworking")
  //   const RazorpayOptions={
  //     description:'sample ',
  //     currency:'INR',
  //     amount:30000,
  //     name:'manogna',
  //     key:'rzp_live_RzRrOjEX8vYUOD',
  //     image:'../../../assets/img/Nex-3.png',
  //     pefill:{
  //       name:'manogan sai',
  //       email:'ccharan954@gmail.com',
  //       phone:'7680878502'
  //     },
  //     theme:{
  //       color:'#FF5733'
  //     },
  //     modal:{
  //       ondismiss:()=>{
  //         console.log("dismissed")

  //       }
  //     }

  //   }
  //   const successCallBack=(payMentId:any)=>{
  //     console.log(payMentId)

  //   }
  //   const FailCallBack=(err:any)=>{
  //     console.log(err)
  //   }

  //   Razorpay.open(RazorpayOptions,successCallBack,FailCallBack,)



  // }




}

