import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/service/common.service';

interface Batch {
  id: number;
  batchTitle: string;
  date: Date;
  timings: string;
  duration: string;
  faculty: string;
  type: string;
  register: string;
  coursefee: number;
}

@Component({
  selector: 'app-upcoming-batches',
  templateUrl: './upcoming-batches.component.html',
  styleUrls: ['./upcoming-batches.component.css']
})
export class UpcomingBatchesComponent implements OnInit {

  batches: [] = [];
  selectedType: string = '';

  constructor(private commonService: CommonService, private server: CommonService, public router: Router) { }

  ngOnInit(): void {
    // Fetch all batches initially
    this.fetchAllBatches();


  }

  fetchAllBatches() {
    // Fetch all batches from the database
    this.commonService.getUpcomingBatches(0).subscribe(res => {
      this.batches = res;

    });
  }

  fetchBatchesByType(type: string) {
    // Fetch batches based on the selected type
    this.commonService.getUpcomingBatches(type).subscribe(res => {
      this.batches = res; // Assuming res is an array of batches

    });
  }

  showOnline() {
    // Update selectedType and fetch batches based on selected type
    this.selectedType = 'Online';
    this.fetchBatchesByType(this.selectedType);
  }

  showOffline() {
    // Update selectedType and fetch batches based on selected type
    this.selectedType = 'Offline';
    this.fetchBatchesByType(this.selectedType);

  }

  // navigateTo(url: string): void {
  //   this.router.navigate([url]);
  // }





  adminUsers: any[] = [];
  selectedUser: any = null;



}
