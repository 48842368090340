import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AdminmodelRoutingModule } from './adminmodel-routing.module';
import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';
import { AdminPanelComponent } from './admin-panel/admin-panel.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EssentialsModule } from '../essentials/essentials.module';
import { ResourceManagerComponent } from './resource-manager/resource-manager.component';
import { AdminProjectsComponent } from './admin-projects/admin-projects.component';




@NgModule({
  declarations: [AdminDashboardComponent, AdminPanelComponent, ResourceManagerComponent,AdminProjectsComponent],
  imports: [
    CommonModule,
    AdminmodelRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    EssentialsModule,


  ]
})
export class AdminmodelModule { }
