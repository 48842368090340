import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CareerOptionsRoutingModule } from './career-options-routing.module';
import { AppModule } from '../app.module';
import { EssentialsModule } from '../essentials/essentials.module';
import { ViewDetailsComponent } from './view-details/view-details.component';
import { JobApplyFormComponent } from './job-apply-form/job-apply-form.component';

@NgModule({
  declarations: [ ViewDetailsComponent, JobApplyFormComponent],
  imports: [
    CommonModule,
    CareerOptionsRoutingModule,
    EssentialsModule,
    
  ]
})
export class CareerOptionsModule { }
