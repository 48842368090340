import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AdminauthGuard implements CanActivate {
  constructor(private router: Router) { }

  canActivate(): boolean {
    const token = localStorage.getItem('adminToken');
    if (token) {
      // Token exists, user is authenticated
      return true;
    } else {
      // Token does not exist, user is not authenticated
      this.router.navigate(['/admin-dashboard']);
      return false;
    }
  }

  
}
