import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthNavbarComponent } from './auth-navbar/auth-navbar.component';
import { PagesDropdownComponent } from './pages-dropdown/pages-dropdown.component';
import { RouterModule } from '@angular/router';
import { FooterComponent } from './footer/footer.component';
import { ScrollToTopComponent } from './scroll-to-top/scroll-to-top.component';



@NgModule({
  declarations: [AuthNavbarComponent, PagesDropdownComponent, FooterComponent, ScrollToTopComponent],
  imports: [
    CommonModule,
    RouterModule
  ],
  exports:[AuthNavbarComponent,PagesDropdownComponent,FooterComponent, ScrollToTopComponent]
})
export class EssentialsModule { }
