import { Component, OnInit, AfterViewInit, ViewChildren, QueryList, ElementRef } from '@angular/core';
import { ScrollService } from './../../courses/scroll.service';

interface Faq {
  question: string;
  answer: string;
  open: boolean;
  maxHeight: string;
}

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.css']
})
export class IndexComponent implements OnInit, AfterViewInit {
  faqs: Faq[] = [
    { question: 'PLANNING', answer: 'We collect all the relevant information from the customer to develop custom software development solutions as per their expectation.', open: false, maxHeight: '0px' },
    { question: 'DESIGNING', answer: 'The system and documents are prepared as per the requirement specifications. This helps us define overall system architecture and technology stack.', open: false, maxHeight: '0px' },
    { question: 'DEFINING', answer: 'Once the requirement analysis phase is completed, the next step is to define and document software needs.', open: false, maxHeight: '0px' },
    { question: 'BUILDING', answer: 'Developers start to build the entire system by writing code using the chosen programming language, techniques, and methodologies.', open: false, maxHeight: '0px' },
    { question: 'TESTING', answer: 'Evaluating the quality of software with the aim of finding and fixing defects.', open: false, maxHeight: '0px' },
    { question: 'DEPLOYMENT', answer: 'The final software is released and checked for deployment issues. if any.', open: false, maxHeight: '0px' },
    { question: 'MAINTENANCE', answer: 'According to the service level agreement,we wnsure that needs continue to be met and that the system continues to perform as ;;er the specification mentioned in the first place.', open: false, maxHeight: '0px' }

  ];

  @ViewChildren('tabContent') tabContents!: QueryList<ElementRef>;

  constructor(private scrollService: ScrollService) {}

  ngOnInit() {
    this.scrollService.scrollToServicesSection$.subscribe(() => {
      this.scrollToServicesSection();
    });
  }

  ngAfterViewInit() {
    this.tabContents.forEach((tabContent, index) => {
      const element = tabContent.nativeElement;
      this.faqs[index].maxHeight = `${element.scrollHeight}px`;
    });
  }

  toggleAccordion(faq: Faq) {
    faq.open = !faq.open;
  }

  scrollToServicesSection() {
    const servicesSection = document.querySelector('#servicesSection');
    if (servicesSection) {
      servicesSection.scrollIntoView({ behavior: 'smooth' });
    }
  }
}
