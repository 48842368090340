import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { element } from 'protractor';

@Component({
  selector: 'app-sap',
  templateUrl: './sap.component.html',
  styleUrls: ['./sap.component.css']
})
export class SapComponent implements OnInit {
  @ViewChild('register', { read: ElementRef }) register: ElementRef;

  constructor(private router: Router) { }
  ngOnInit(): void {
  }
  
  
  navigateToComponentWithScroll() {
    this.register.nativeElement.scrollIntoView();
      }
    };



