import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Subscription } from 'rxjs';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { CommonService } from 'src/app/service/common.service';

@Component({
  selector: 'app-student-dashbord',
  templateUrl: './student-dashbord.component.html',
  styleUrls: ['./student-dashbord.component.css']
})
export class StudentDashbordComponent implements OnInit, OnDestroy {
  public email: string;
  public userData: any;  // Initialize userData to an empty object
  private routerEventsSubscription: Subscription | undefined;
  navbarOpen = false;

  // Properties for toggling the side navbar, dropdown, and sections
  showSideNavbar = false;
  showDropdown = false;
  showMyProfileSection = false;  // Updated to track the My Profile section
  showProjectsSection = false;  // Updated to track the Projects section
  // showVideo = true;  // Show the video by default on page load
  // showControls = true;  // Show video controls by default 
  videoUrl: SafeUrl = '';

  constructor(
    private router: Router,
    private http: CommonService,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    this.email = localStorage.getItem('email') ?? '';
    this.getUserData();

    // Subscribe to router events
    this.routerEventsSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        // Perform any action on navigation start if needed
      }
    });

    // Load the default video
    // this.onWatch(1);
  }

  ngOnDestroy(): void {
    // Unsubscribe from router events to avoid memory leaks
    if (this.routerEventsSubscription) {
      this.routerEventsSubscription.unsubscribe();
    }
  }

  logout(): void {
    localStorage.removeItem('jwtToken');
    this.router.navigate(['/loginform']);
  }

  getUserData(): void {
    this.http.getUserDataByEmail(this.email).subscribe(data => {
      this.userData = data;
    });
  }

  // Method to toggle the side navbar
  toggleSideNavbar(event: Event): void {
    event.preventDefault(); // Prevents the default behavior of the anchor element
    this.showSideNavbar = !this.showSideNavbar;
  }

  // Method to toggle dropdown and prevent default action
  toggleDropdown(event: Event): void {
    event.preventDefault(); // Prevents the default behavior of the anchor element
    this.showDropdown = !this.showDropdown;
  }

  // Method to hide dropdown and prevent default action
  hideDropdown(event: Event): void {
    event.preventDefault(); // Prevents the default behavior of the anchor element
    this.showDropdown = false;
  }

  // Method to set navbar open
  setNavbarOpen(): void {
    this.navbarOpen = !this.navbarOpen;
  }

  // Method to toggle the visibility of the My Profile section
  toggleMyProFileSection(): void {
    this.showMyProfileSection = true;
    this.showProjectsSection = false;
    // this.showVideo = false;  // Hide the video when My Profile is clicked
    this.showSideNavbar = false;  // Close the side navbar
    
  }

  // Method to toggle the visibility of the Projects section
  toggleProjectsSection(): void {
    this.showMyProfileSection = false;
    this.showProjectsSection = true;
    // this.showVideo = false;  // Hide the video when Projects is clicked
    this.showSideNavbar = false;  // Close the side navbar
  }

  // Method to show the video
  // showVideoSection(): void {
  //   this.showMyProfileSection = false;
  //   this.showProjectsSection = false;
  //   this.showVideo = true;
  //   this.onWatch(1);  // Ensure video is reloaded
  // }

  // onWatch(videoId: number): void {
  //   this.http.watchVideo(videoId).subscribe(
  //     (blob: Blob) => {
  //       const url = window.URL.createObjectURL(blob);
  //       this.videoUrl = this.sanitizer.bypassSecurityTrustUrl(url);
  //     },
  //     error => {
  //       console.error('Error loading video:', error);
  //     }
  //   );
  // }

  // downloadFile(fileId: number): void {
  //   this.http.downloadFile(fileId).subscribe((blob: Blob) => {
  //     const url = window.URL.createObjectURL(blob);
  //     const a = document.createElement('a');
  //     a.href = url;
  //     a.download = 'downloaded-file';
  //     a.click();
  //     window.URL.revokeObjectURL(url);
  //   });
  // }
}
