import { Component, OnInit, ElementRef, ViewChild, HostListener } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from "@angular/forms";
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/service/common.service';

@Component({
  selector: 'app-resource-manager',
  templateUrl: './resource-manager.component.html',
  styleUrls: ['./resource-manager.component.css']
})
export class ResourceManagerComponent implements OnInit {

  selectedOption: string;
  usersAllDetails: any[] = [];
  showTable: boolean = false;
  tableUserData: any[] = [];
  user: FormGroup;
  loading: boolean = false;
  text: string;
  success: boolean = false;
  failure: boolean = false;

  navbarOpen = false;
  profileDropdownOpen = false;
  sidebarOpen = true;
  formVisible = false;
  rolesDropdownOpen: boolean = false;
  usersDropdownOpen = false; 
  selectedUser: any; 
  roleName: string = '';
  roleDescription: string = '';

  @ViewChild('profileDropdown') profileDropdown: ElementRef;

  constructor(
    private router: Router,
    private eRef: ElementRef,
    private common: CommonService,
    private formBuilder: FormBuilder
  ) {
    this.openSidebar();
  }

  ngOnInit(): void {
    this.user = this.formBuilder.group({
      firstName: ["", [Validators.required, this.noWhitespaceValidator, Validators.pattern("[a-zA-Z][a-zA-Z ]*")]],
      lastName: ["", [Validators.required, this.noWhitespaceValidator, Validators.pattern("[a-zA-Z][a-zA-Z ]*")]],
      id: ["", [Validators.required, this.noWhitespaceValidator]],
      email: ["", [Validators.required, Validators.email]],
      password: ["", [Validators.required, this.noWhitespaceValidator]],
      phoneNo: ["", [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
      dateOfEntry: [this.getDefaultDate(), [Validators.required, this.noWhitespaceValidator]],
      mode: ["", [Validators.required]],
      course: ["", [Validators.required, this.noWhitespaceValidator]],
      amount: ["", [Validators.required, this.noWhitespaceValidator]]
    });

    this.getAllUsers();
  }

  getAllUsers() {
    this.common.getAllAdminUserDetails().subscribe(
      (res: any[]) => {
        this.usersAllDetails = res;
      },
      (error) => {
        console.error('Error fetching user details:', error);
      }
    );
  }

  getDefaultDate(): string {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  } 

  toggleSidebar() {
    this.sidebarOpen = !this.sidebarOpen;
  }

  toggleProfileDropdown() {
    this.profileDropdownOpen = !this.profileDropdownOpen;
  }

  closeProfileDropdown() {
    this.profileDropdownOpen = false;
  }

  toggleUsersDropdown() {
    this.usersDropdownOpen = !this.usersDropdownOpen;
  
    this.rolesDropdownOpen = false;
  }

  closeUsersDropdown() {
    this.usersDropdownOpen = false;
  }

  toggleRolesDropdown() {
    this.rolesDropdownOpen = !this.rolesDropdownOpen;
   
    this.usersDropdownOpen = false;
  }

  openSidebar() {
    this.sidebarOpen = true;
  }

  closeSidebar() {
    this.sidebarOpen = false;
  }

  showForm() {
    this.formVisible = true;
    this.openSidebar();
    this.closeProfileDropdown();
  }

  hideForm() {
    this.formVisible = false;
  }

  logout() {
    localStorage.removeItem('adminToken');
    this.router.navigate(['/admin-panel']);
  }

  openResourceManagerForm() {
    this.showForm();
  }

  openSettings() {
    this.selectedOption = 'settings';
  }

  showUsers() {
    this.selectedOption = 'users';
  }

  showProfiles() {
    this.selectedOption = 'profiles';
  }

  showRoles() {
    this.selectedOption = 'roles';
    
  }

  selectUserOption(option: string) {
    this.selectedOption = option;
  }

  goToAdminDashboard() {
    this.router.navigate(['/admin-dashboard']);
  }

  @HostListener('document:click', ['$event'])
  onClick(event) {
    if (this.profileDropdownOpen && !this.eRef.nativeElement.contains(event.target)) {
      this.closeProfileDropdown();
    }
    if (this.usersDropdownOpen && !this.eRef.nativeElement.contains(event.target)) {
      this.closeUsersDropdown();
    }
    if (this.rolesDropdownOpen && !this.eRef.nativeElement.contains(event.target)) {
      this.rolesDropdownOpen = false;
    }
  }

  onSubmit() {
    const data = this.user.value;
    if (this.user.invalid) {
      return;
    }
    this.loading = true;
    this.common.submitAdminUsers(data).subscribe(
      (res) => {
        if (res && res.message === 'User created successfully.') {
          this.success = true;
          this.failure = false;
          this.user.reset();
        } else if (res && res.error === 'Email address already registered') {
          this.failure = true;
          this.success = false;
        }
        this.loading = false;
      },
      (err) => {
        console.error('Error submitting form:', err);
        this.loading = false;
      }
    );
  }

  showTables(user: any) {
    this.selectedUser = user;
  }

  
  isEditing = false;

  selectUser(user: any) {
    this.selectedUser = user;
    this.isEditing = false;
  }

  editUser() {
    this.isEditing = true;
  }

  cancelEdit() {
    this.isEditing = false;
  }

  updateUser() {
   
    this.isEditing = false;
    alert(`User updated successfully.`);
  }

  saveRole() {
    
    console.log('Role Name:', this.roleName);
    console.log('Role Description:', this.roleDescription);

 
    this.roleName = '';
    this.roleDescription = '';
    this.rolesDropdownOpen = false;
  }
}
