// carousel.component.ts
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.css']
})
export class CarouselComponent implements OnInit {
  private currentIndex = 0;
  private interval: any;

  ngOnInit(): void {
    this.startAutoCarousel();
  }

  ngOnDestroy(): void {
    this.stopAutoCarousel();
  }

  private startAutoCarousel(): void {
    this.interval = setInterval(() => {
      this.nextSlide();
    }, 3000); // Change slide every 3 seconds (adjust as needed)
  }

  private stopAutoCarousel(): void {
    clearInterval(this.interval);
  }

  private updateCarousel(): void {
    const track = document.querySelector('.carousel-track') as HTMLElement;
    if (track) {
      const transformValue = `translateX(-${this.currentIndex * 450}px)`;
      track.style.transform = transformValue;
    }
  }

  prevSlide(): void {
    this.currentIndex = (this.currentIndex - 1 + 3) % 3;
    this.updateCarousel();
  }

  nextSlide(): void {
    this.currentIndex = (this.currentIndex + 1) % 3;
    this.updateCarousel();
  }
}
