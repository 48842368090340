import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StudentmodelRoutingModule } from './studentmodel-routing.module';
import { StudentMaterialsComponent } from './student-materials/student-materials.component';
import { EssentialsModule } from '../essentials/essentials.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StudentDashbordComponent } from './student-dashbord/student-dashbord.component';
import { StudentProfileComponent } from './student-profile/student-profile.component';
import { StudentProjectsComponent } from './student-projects/student-projects.component';
import { AboutStudentComponent } from './about-student/about-student.component';
import { StudentAssignmentsComponent } from './student-assignments/student-assignments.component';
import { StudentCoursesComponent } from './student-courses/student-courses.component';


@NgModule({
  declarations: [StudentDashbordComponent,StudentMaterialsComponent, StudentProfileComponent, StudentProjectsComponent, AboutStudentComponent, StudentAssignmentsComponent, StudentCoursesComponent],
  imports: [
    CommonModule,
    StudentmodelRoutingModule,
    EssentialsModule,
    ReactiveFormsModule,
    FormsModule
  ]
})
export class StudentmodelModule { }
