import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-image-slider',
  templateUrl: './image-slider.component.html',
  styleUrls: ['./image-slider.component.css'],
})
export class ImageSliderComponent implements OnInit {
  images = [ 'assets/img/js-sliderimg.jpg','assets/img/nodejs-sliderimg.jpg' ,'assets/img/devops-course.jpg','assets/img/python-img-slider.png', 'assets/img/angular-course.jpg', 'assets/img/java-course.jpg',];
  currentIndex = 0;
  validateIndex = 0;
  sliderStyle: any; // Declare the sliderStyle property

  ngOnInit() {
    this.updateSliderStyle();
  }

  updateSliderStyle() {
    // if(this.validateIndex != 6){
      let translatePercent = ((this.currentIndex * 100) == 0 ) ? `0%` : `-${this.currentIndex * 100}%`
      console.log(`translateX-${translatePercent},%`,this.currentIndex,this.validateIndex)
      this.sliderStyle = {
        transform: `translateX(${translatePercent})`,
        transition: `${(translatePercent == '0%' || translatePercent == '-500%') ? '' :'transform 0.5s ease-in-out'}`,
      };
    // }
  }

  moveSlider(direction: number) {
    this.validateIndex = ((this.currentIndex + direction) == -1) ? 5 : (this.currentIndex + direction)
    this.currentIndex =  this.validateIndex % this.images.length;
    this.updateSliderStyle();
  }
}
