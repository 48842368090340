import { Component, OnInit } from "@angular/core";
import { ScrollService } from './../../../courses/scroll.service';
@Component({
  selector: "app-index-navbar",
  templateUrl: "./index-navbar.component.html",
})
export class IndexNavbarComponent implements OnInit {
  navbarOpen = false;

  constructor(private scrollService: ScrollService) {}

  scrollToServicesSection() {
    this.scrollService.scrollServicesSection();
  }
  ngOnInit(): void {}

  setNavbarOpen() {
    this.navbarOpen = !this.navbarOpen;
  }
}
