import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminPanelComponent } from './admin-panel/admin-panel.component';
import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';
import { AdminauthGuard } from './admin-dashboard/adminauth.guard';
import { ResourceManagerComponent } from './resource-manager/resource-manager.component';
import { AdminProjectsComponent } from './admin-projects/admin-projects.component';



const routes: Routes = [
  { path: '', component: AdminPanelComponent },

  {
    path: '',
    canActivate: [AdminauthGuard],
    children: [
      { path: 'admin-dashboardMain', component: AdminDashboardComponent },
      { path: 'resource-manager', component: ResourceManagerComponent },
      {path:'admin-projects',component:AdminProjectsComponent}
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminmodelRoutingModule { }
