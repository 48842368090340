import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { CommonService } from "src/app/service/common.service";

@Component({
  selector: "app-internship",
  templateUrl: "./internship.component.html",
  styleUrls: ["./internship.component.css"],
})

export class InternshipComponent implements OnInit {
  internshipForm: FormGroup;
  loading: boolean = false;
  text: string;
  success: boolean;
  failure: boolean;
  internShipTypes: Array<any>;
  courseNames: Array<any>;
  
  referalCode: string;

  constructor(
    private common: CommonService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {

    this.internshipForm = this.formBuilder.group({
      fullName: ["", [Validators.required, this.noWhitespace ,  Validators.pattern("[a-zA-Z][a-zA-Z ]+") ]],
      email: ["", [Validators.required, Validators.email, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
      phoneNo: ["", [Validators.required , Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
      courseType: ["", [Validators.required, this.noWhitespace, Validators.pattern("[a-zA-Z][a-zA-Z ]+")]],
      collegeName: ["",[ Validators.required , this.noWhitespace]],
      internshipType: ["", Validators.required, ],
      courseSelected: ["", Validators.required],
      referalCode: [""]
    });

    

    this.common.getInternshipTypes().subscribe((res) => {
      this.internShipTypes = res.data
      this.internshipForm.get('internshipType').setValue("default");
    })

    this.common.getCourseNames().subscribe((res) => {
      this.courseNames = res.data
      this.internshipForm.get('courseSelected').setValue("default");
    })

    

   

    this.route.queryParams.subscribe(params => {
      this.referalCode = params['code']
      if (this.referalCode) {
        this.internshipForm.controls.referalCode.setValue(this.referalCode);
      }
    });

  }

  noWhitespace(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
  
    return isValid ? null : { whitespace: true };
  }
  

  onSubmit() {
    const data = this.internshipForm.value;
    if (this.internshipForm.invalid) {
      return;
    }
    this.loading = true;
    this.common.internship(data).subscribe(
      (res) => {
        if (res && res["success"] === "Data stored and Email sent successfully") {
          this.success = true;
          this.internshipForm.reset();
          this.loading = false;
          setTimeout(() => {
            this.success = false;
          }, 10000);
        } else if (res && res["error"]) {
          this.failure = true;
          this.internshipForm.reset();
          this.loading = false;
          setTimeout(() => {
            this.failure = false;
          }, 10000);
        }
      },
      (err) => {
        if (err) {
          this.loading = false;
        }
      }
    );
  }
}

