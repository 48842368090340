import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { element } from 'protractor';

@Component({
  selector: 'app-qualityanalyst',
  templateUrl: './qualityanalyst.component.html',
  styleUrls: ['./qualityanalyst.component.css']
})
export class QualityanalystComponent implements OnInit {

  @ViewChild('register', { read: ElementRef }) register: ElementRef;

  constructor(private router: Router) { }
  ngOnInit(): void {
  }
  
  
  navigateToComponentWithScroll() {
    this.register.nativeElement.scrollIntoView();
      }
    };


