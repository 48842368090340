import { Component, OnInit, ElementRef, ViewChild, HostListener } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.css']
})
export class AdminDashboardComponent implements OnInit {
  navbarOpen = false;
  profileDropdownOpen = false;
  sidebarOpen = false;
  formVisible = false;
  selectedOption: string; // Track selected option for sidebar menu

  @ViewChild('profileDropdown') profileDropdown: ElementRef;

  constructor(private router: Router, private eRef: ElementRef) { }

  ngOnInit(): void { }

  toggleSidebar() {
    this.sidebarOpen = !this.sidebarOpen;
  }

  toggleProfileDropdown() {
    this.profileDropdownOpen = !this.profileDropdownOpen;
  }

  closeProfileDropdown() {
    this.profileDropdownOpen = false;
  }

  openSidebar() {
    this.sidebarOpen = true;
  }

  closeSidebar() {
    this.sidebarOpen = false;
  }

  showForm() {
    this.formVisible = true;
    this.openSidebar();
    this.closeProfileDropdown();
  }

  hideForm() {
    this.formVisible = false;
  }

  logout() {
    localStorage.removeItem('adminToken');
    this.router.navigate(['/admin-dashboard']);
  }

  openResourceManagerForm() {
    this.showForm();
  }

  openSettings() {
    this.sidebarOpen = true; // Open sidebar when Settings is clicked
    this.selectedOption = ''; // Reset selected option
  }

  showUsers() {
    this.selectedOption = 'users'; // Show Users content
  }

  showProfiles() {
    this.selectedOption = 'profiles'; // Show Profiles content
  }

  showRoles() {
    this.selectedOption = 'roles'; // Show Roles content
  }

  @HostListener('document:click', ['$event'])
  onClick(event) {
    if (this.profileDropdownOpen && !this.eRef.nativeElement.contains(event.target)) {
      this.closeProfileDropdown();
    }
  }


  openCertificates() { }

  openAttendance() { }

  openProjects() { }

}
