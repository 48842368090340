import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import{Router} from '@angular/router';

import { PaymentserviceService } from '../paymentservice.service';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})
export class PaymentComponent implements OnInit {
  amount=0;
  @ViewChild('paymentRef', {static : true}) paymenRef!: ElementRef

  constructor(private router: Router, private payment:  PaymentserviceService) { }

  ngOnInit(): void {
    // this.amount=this.payment.totalAmount;
    this.amount= 100;
    // console.log(window.paypal.Buttons().render(this.paymenRef.nativeElement))
  }


}
