import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { createPopper, Instance } from "@popperjs/core";
import { ScrollService } from "src/app/courses/scroll.service";

@Component({
  selector: "app-index-dropdown",
  templateUrl: "./index-dropdown.component.html",
})
export class IndexDropdownComponent implements OnInit {

  isDropdownVisible = false;
  popperInstance: Instance;
  dropdownPopoverShow;  


  constructor(private scrollService: ScrollService) {}

  ngOnInit() {}

  toggleDropdown(event: Event) {
    event.preventDefault();
    this.isDropdownVisible = !this.isDropdownVisible;

    if (this.isDropdownVisible) {
      this.createPopper();
    } else {
      this.destroyPopper();
    }
  }

  createPopper() {
    this.popperInstance = createPopper(
      this.btnDropdownRef.nativeElement,
      this.popoverDropdownRef.nativeElement,
      {
        placement: "bottom-start",
      }
    );
  }

  destroyPopper() {
    if (this.popperInstance) {
      this.popperInstance.destroy();
      this.popperInstance = null;
    }
  }

  @ViewChild("btnDropdownRef") btnDropdownRef: ElementRef;
  @ViewChild("popoverDropdownRef") popoverDropdownRef: ElementRef;

  scrollToServicesSection() {
    this.scrollService.scrollServicesSection();
  }
}
