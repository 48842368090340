import { Component } from "@angular/core";
import { Location } from '@angular/common';

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
})
export class AppComponent {
  // constructor(private location: Location) {
  //   history.pushState(null, null, window.location.href);
  //   window.onpopstate = () => {
  //     history.go(1);
  //   };
  // }
}
