import { Component, OnInit } from "@angular/core";
import { AuthService } from "src/app/service/auth.service";

@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
})
export class ProfileComponent implements OnInit {
  constructor(private auth: AuthService, ) {}

  ngOnInit(): void {
      if(localStorage.getItem['token']){
        this.auth.dashBoard(localStorage.getItem('token')).subscribe(res =>{
          if(res && res['status'] === 'ok'){
            console.log('we are in dashboard')
          }else{
            console.log("Something went wrong in dashboard")
          }

        }, (err)=>{
          if(err){
            console.log("we got error")
          }
        })
      }
   
  }
}
