import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})

export class CommonService {
  constructor(private http: HttpClient) { }
  public mainUrl = "https://nexmindsolutions.com/5500";

  contact(data): Observable<any> {
    return this.http.post(`${this.mainUrl}/api/support`, data, {
      headers: new HttpHeaders().set("Accept", "application/json"),
    });
  }

  internship(data): Observable<any> {
    return this.http.post(`${this.mainUrl}/api/internship`, data, {
      headers: new HttpHeaders().set("Accept", "application/json"),
    });
  }

  getInternshipTypes(): Observable<any> {
    return this.http.get(`${this.mainUrl}/api/getInternshipTypes`);
  }

  getCourseNames(): Observable<any> {
    return this.http.get(`${this.mainUrl}/api/getCourseNames`);
  }

  trainings(data): Observable<any> {
    return this.http.post(`${this.mainUrl}/api/registrations`, data, {
      headers: new HttpHeaders().set("Accept", "application/json"),
    });
  }
  getTrainingTypes(): Observable<any> {
    return this.http.get(`${this.mainUrl}/api/gettrainingTypes`);
  }

  getUpcomingBatches(type: any): Observable<any> {
    return this.http.get(
      `${this.mainUrl}/api/sql/fetchupcomingbatches/${type}`
    );
  }

  upcomingbatches(data): Observable<any> {
    return this.http.post(`${this.mainUrl}/api/upcomingbatches`, data, {
      headers: new HttpHeaders().set("Accept", "application/json"),
    });
  }

  getDinamiccourses(id: number): Observable<any> {
    return this.http.get(`${this.mainUrl}/api/getDynamicCourses/${id}`);
  }

  submitAdminUsers(data: any): Observable<any> {
    return this.http.post<any>(`${this.mainUrl}/admin/admin-user`, data);
  }

  // /  Admin login ////////////////////////////////////////

  private adminLoginUrl: string = `${this.mainUrl}/admin/adminLogin`;
  admin_login(email: string, password: string): Observable<any> {
    return this.http.post(this.adminLoginUrl, { email, password });
  }

  /// student login ////////////////////////////////////////////////

  private loginUrl: string = `${this.mainUrl}/student/loginStudent`;
  login(email: string, password: string): Observable<any> {
    return this.http.post(this.loginUrl, { email, password });
  }

  ///// student Registration ////////////////////////////////////

  private signupUrl: string = `${this.mainUrl}/registrationMain`;
  signup(formData: any): Observable<any> {
    return this.http.post(this.signupUrl, formData);
  }

  ////// GET CERTIFICATE /////////////////////////////////

  private baseUrl = `${this.mainUrl}/student/getcertificates`;
  getUserDataByEmail(email: string): Observable<any> {
    return this.http.get(`${this.baseUrl}/${email}`);
  }

  private getAdminUserDetailUrl = `${this.mainUrl}/admin/getAllAdminUsers`;
  getAllAdminUserDetails() {
    return this.http.get(`${this.getAdminUserDetailUrl}`);
  }

  private updateAdminUserDetailUrl = `${this.mainUrl}/admin/admin-user`;
  updateAdminUserDetails(email: any, userDetails: any): Observable<any> {
    return this.http.put(
      `${this.updateAdminUserDetailUrl}/${email}`,
      userDetails
    );
  }

  public studentDataUrl = `${this.mainUrl}/student/getAdminUserStudents`;

  studentDataUrlApi(email: any) {
    return this.http.get(`${this.studentDataUrl}/${email}`);
  }

  ///////////////  Files Upload Api /////////////////////

  private adminfileUpload = `${this.mainUrl}/admin/upload`;

  uploadFile(file: File): Observable<any> {
    const formData = new FormData();
    formData.append("file", file);
    return this.http.post<any>(this.adminfileUpload, formData);
  }

  //////////////// download File  ////////////////////

  private studentDownLoadFile = `${this.mainUrl}/student`;

  downloadFile(fileId: number): Observable<Blob> {
    return this.http
      .get(`${this.studentDownLoadFile}/download/${fileId}`, {
        responseType: "blob",
        observe: "response",
      })
      .pipe(
        map((res: HttpResponse<Blob>) => {
          const contentDisposition = res.headers.get("content-disposition");
          const matches = /filename="(.+)"/.exec(contentDisposition);
          const filename = matches ? matches[1] : "untitled";

          return new Blob([res.body], { type: res.body.type });
        })
      );
  }

  //////////////// upload video  ////////////////////

  public uploadVideos = `${this.mainUrl}/admin`;
  uploadVideo(file: File): Observable<any> {
    const formData: FormData = new FormData();
    formData.append("video", file, file.name);

    return this.http.post(`${this.uploadVideos}/uploadVideo`, formData);
  }

  //////////////// watch video  ////////////////////

  public watchdVideos = `${this.mainUrl}/student`;
  watchVideo(videoId: number): Observable<Blob> {
    return this.http.get(`${this.watchdVideos}/watchVideo/${videoId}`, {
      responseType: "blob",
    });
  }

  ////////////////////////////////////////////////

  private paymentUrl = `${this.mainUrl}/student/api`;

  storePaymentDetails(paymentDetails: any): Observable<any> {
    return this.http.post(`${this.paymentUrl}/payments`, paymentDetails, {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    });
  }

  //////////////////////////////////////////////

  getAllData(): Observable<any> {
    return this.http.get(`${this.mainUrl}/api/getDynamicLiveClasses`);
  }

  getCourseById(id: string): Observable<any> {
    return this.http.get(`${this.mainUrl}/api/getDynamicLiveClasses/${id}`);
  }

  //////////////// Live clasess Form ////////////////////

  postLiveClassesFormData(formData: any): Observable<any> {
    return this.http.post(`${this.mainUrl}/api/liveclasses`, formData);
  }



  // private apiUrl = 'http://localhost:5500/api/getDynamicLiveClasses/1';



  // getTechnologies(): Observable<any> {
  //   return this.http.get<any>(this.apiUrl);
  // }
}

