import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";

// layouts
//import { AdminComponent } from "./layouts/admin/admin.component";
import { AuthComponent } from "./layouts/auth/auth.component";

// admin views
// import { DashboardComponent } from "./views/admin/dashboard/dashboard.component";
// import { MapsComponent } from "./views/admin/maps/maps.component";
// import { SettingsComponent } from "./views/admin/settings/settings.component";
// import { TablesComponent } from "./views/admin/tables/tables.component";

// auth views
import { LoginComponent } from "./views/auth/login/login.component";
import { RegisterComponent } from "./views/auth/register/register.component";

// no layouts views
import { IndexComponent } from "./views/index/index.component";
import { LandingComponent } from "./views/landing/landing.component";
import { ProfileComponent } from "./views/profile/profile.component";

// components for views and layouts

import { AdminNavbarComponent } from "./components/navbars/admin-navbar/admin-navbar.component";
// import { AuthNavbarComponent } from "./components/navbars/auth-navbar/auth-navbar.component";
// import { CardBarChartComponent } from "./components/cards/card-bar-chart/card-bar-chart.component";
// import { CardLineChartComponent } from "./components/cards/card-line-chart/card-line-chart.component";
// import { CardPageVisitsComponent } from "./components/cards/card-page-visits/card-page-visits.component";
// import { CardProfileComponent } from "./components/cards/card-profile/card-profile.component";
// import { CardSettingsComponent } from "./components/cards/card-settings/card-settings.component";
// import { CardSocialTrafficComponent } from "./components/cards/card-social-traffic/card-social-traffic.component";
// import { CardStatsComponent } from "./components/cards/card-stats/card-stats.component";
// import { CardTableComponent } from "./components/cards/card-table/card-table.component";
import { FooterAdminComponent } from "./components/footers/footer-admin/footer-admin.component";
// import { FooterComponent } from "./components/footers/footer/footer.component";
import { FooterSmallComponent } from "./components/footers/footer-small/footer-small.component";
import { HeaderStatsComponent } from "./components/headers/header-stats/header-stats.component";
import { IndexNavbarComponent } from "./components/navbars/index-navbar/index-navbar.component";
import { MapExampleComponent } from "./components/maps/map-example/map-example.component";
import { IndexDropdownComponent } from "./components/dropdowns/index-dropdown/index-dropdown.component";
// import { TableDropdownComponent } from "./components/dropdowns/table-dropdown/table-dropdown.component";
// import { PagesDropdownComponent } from "./components/dropdowns/pages-dropdown/pages-dropdown.component";
// import { NotificationDropdownComponent } from "./components/dropdowns/notification-dropdown/notification-dropdown.component";
import { SidebarComponent } from "./components/sidebar/sidebar.component";
import { QualityanalystComponent } from './courses/qualityanalyst/qualityanalyst.component';
import { JavascriptComponent } from './courses/javascript/javascript.component';
import { JavaComponent } from './courses/java/java.component';
import { SalesforceComponent } from './courses/salesforce/salesforce.component';
import { NodejsComponent } from './courses/nodejs/nodejs.component';
import { ReactjsComponent } from './courses/reactjs/reactjs.component';
import { AngularComponent } from './courses/angular/angular.component';
import { DevopsComponent } from './courses/devops/devops.component';
import { SapComponent } from './courses/sap/sap.component';
import { PythonComponent } from './courses/python/python.component';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";

import { CybersecurityComponent } from "./courses/cybersecurity/cybersecurity.component";
import { AptitudeComponent } from "./courses/aptitude/aptitude.component";
import { WhatWeDoComponent } from './components/what-we-do/what-we-do.component';
// import { CarouselComponent } from './components/carousel/carousel.component';
import { InternshipComponent } from './courses/internship/internship.component';
import { TrainingsComponent } from './courses/trainings/trainings.component';
import { CareerOptionsModule } from "./career-options/career-options.module";
import { CareersComponent } from './views/careers/careers.component';
import { EssentialsModule } from "./essentials/essentials.module";
import { ImageSliderComponent } from './courses/image-slider/image-slider.component';
import { PrivacyPolicyComponent } from './courses/privacy-policy/privacy-policy.component';
import { ScrollTotopComponent } from './courses/scroll-totop/scroll-totop.component';
import { UpcomingBatchesComponent } from './courses/upcoming-batches/upcoming-batches.component';
import { CarouselComponent } from './courses/carousel/carousel.component';
import { LoginformComponent } from './loginform/loginform.component';
import { SignupComponent } from './signup/signup.component';
import { PaymentComponent } from './payment/payment.component';
import { PaymentserviceService } from "./paymentservice.service";
// import { StudentDashbordComponent } from './student_dashboard/student-dashbord/student-dashbord.componentdashbord.component';
// import { AdminPanelComponent } from './admin_dashboard/admin-panel/admin-panel.component';
// import { AdminDashboardComponent } from './admin_dashboard/admin-dashboard/admin-dashboard.component';
// import { ResourceManagerComponent } from './admin_dashboard/resource-manager/resource-manager.component';
import { AdminmodelModule } from "./admin_dashboard/adminmodel.module";
import { StudentmodelModule } from "./student_dashboard/studentmodel.module";
import { LiveclassesComponent } from './courses/liveclasses/liveclasses.component';


//import { UserDropdownComponent } from "./components/dropdowns/user-dropdown/user-dropdown.component";

@NgModule({
  declarations: [
    AppComponent,
    //DashboardComponent,
    //CardBarChartComponent,
    //CardLineChartComponent,
    IndexDropdownComponent,
    //  PagesDropdownComponent,
    // TableDropdownComponent,
    // NotificationDropdownComponent,
    //UserDropdownComponent,
    SidebarComponent,
    // FooterComponent,
    FooterSmallComponent,
    FooterAdminComponent,
    // CardPageVisitsComponent,
    // CardProfileComponent,
    // CardSettingsComponent,
    // CardSocialTrafficComponent,
    // CardStatsComponent,
    // CardTableComponent,
    HeaderStatsComponent,
    MapExampleComponent,
    // AuthNavbarComponent,
    AdminNavbarComponent,
    IndexNavbarComponent,
    //AdminComponent,
    AuthComponent,
    // MapsComponent,
    // SettingsComponent,
    // TablesComponent,
    LoginComponent,
    RegisterComponent,
    IndexComponent,
    LandingComponent,
    ProfileComponent,
    QualityanalystComponent,
    JavascriptComponent,
    JavaComponent,
    SalesforceComponent,
    NodejsComponent,
    ReactjsComponent,
    AngularComponent,
    DevopsComponent,
    SapComponent,
    PythonComponent,
    CybersecurityComponent,
    AptitudeComponent,
    WhatWeDoComponent,
    // CarouselComponent,
    InternshipComponent,
    TrainingsComponent,
    CareersComponent,
    ImageSliderComponent,
    PrivacyPolicyComponent,
    ScrollTotopComponent,
    UpcomingBatchesComponent,
    CarouselComponent,

    LoginformComponent,

    SignupComponent,

    PaymentComponent,

    LiveclassesComponent,


    // StudentDashbordComponent,

    // AdminPanelComponent,

    // AdminDashboardComponent,

    // ResourceManagerComponent,
    
    //JavascriptComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    CareerOptionsModule,
    EssentialsModule,
    AdminmodelModule,
    StudentmodelModule
  ],
  providers: [PaymentserviceService],
  bootstrap: [AppComponent],
})
export class AppModule { }
