import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Subscription } from 'rxjs';
import { CommonService } from 'src/app/service/common.service';


@Component({
  selector: 'app-student-projects',
  templateUrl: './student-projects.component.html',
  styleUrls: ['./student-projects.component.css']
})
export class StudentProjectsComponent implements OnInit, OnDestroy {
  userData: any;  
  public email: string;
  // public userData: any;
  showSideNavbar: boolean = false;
  // routerEventsSubscription: Subscription;
  private routerEventsSubscription: Subscription | undefined;
  navbarOpen = false;

  constructor(private router: Router, private http:CommonService) { }

  ngOnInit(): void {

    this.email = localStorage.getItem('email') ?? '';
    this.getUserData();

    this.routerEventsSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.showSideNavbar = false;
      }
    });
  }

  toggleSideNavbar(event: MouseEvent): void {
    this.showSideNavbar = !this.showSideNavbar;
  }

  toggleMyProFileSection(): void {
    
    console.log('My Profile section toggled');
  }

  toggleProjectsSection(): void {
    
    console.log('Projects section toggled');
  }

  ngOnDestroy(): void {
   
    if (this.routerEventsSubscription) {
      this.routerEventsSubscription.unsubscribe();
    }
  }

  logout(): void {
    localStorage.removeItem('jwtToken');
    this.router.navigate(['/student-dashboard']);
  }
  getUserData(): void {
    this.http.getUserDataByEmail(this.email).subscribe(data => {
      this.userData = data;
    });
  }

  setNavbarOpen(): void {
    this.navbarOpen = !this.navbarOpen;
  }


  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent): void {
    const target = event.target as HTMLElement;
    const clickedInsideNavbar = target.closest('nav');
    if (!clickedInsideNavbar) {
      this.showSideNavbar = false;
    }
  }
}
