import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators,FormControl} from "@angular/forms";
import { Router } from "@angular/router";
import { CommonService } from "src/app/service/common.service";

@Component({
  selector: "app-landing",
  templateUrl: "./landing.component.html",
})
export class LandingComponent implements OnInit {

  contactForm: FormGroup;
  loading:boolean = false;
  text:string;
  success:boolean;
  failure:boolean;

  constructor( private common:CommonService,private formBuilder:FormBuilder) {

  }



  ngOnInit(): void {
    this.contactForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phoneno:['', Validators.required],
      message: ['', Validators.required]
    })
  }

  onSubmit(){
    const data = this.contactForm.value;
    if (this.contactForm.invalid) {
      return;

    }
    this.loading = true;
    this.common.contact(data).subscribe((res) =>{
      if(res && res['status'] === 'ok'){
        this.success = true;
        this.contactForm.reset();
        this.loading = false;
        setTimeout(()=>{
          this.success = false;
        },10000)

      }
      else if(res && res['status'] === 'error'){
        this.failure = true;
        this.contactForm.reset();
        this.loading = false;
        setTimeout(()=>{
          this.failure = false;
        },10000)
      }
    }
    ,(err)=>{
      if(err){
        this.loading = false;
      }
    })
  }

  // onSubmit(){
  //   console.log(this.contactForm)
  // }
}
